<script>

import router from '../../index'
export default {
  created() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userEmail");
    if (localStorage.getItem('userType') === "applicant") { console.log(localStorage.getItem('userType'), "111");router.push("/login") } else { console.log(localStorage.getItem('userType'), "222");router.push("/staff_login?logout") }
    // router.push('/login'); 
  }
}
</script>